import { } from "prop-types";

import MapPage from "../../components/MapPage/MapPage";

const propTypes = {};

const GatsbyMap = (props) => {
  return <MapPage />;
};

GatsbyMap.propTypes = propTypes;

export default GatsbyMap;
